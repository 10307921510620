import { Heading, Modal, Text } from '@lounge-fe/ui-kit'
import { LoginForm } from '../Login/LoginForm'
import { LoginModalProps } from './types'

export const LoginModal = ({
  title,
  description,
  onSuccess,
  redirect = false,
  trigger = null,
}: LoginModalProps) => {
  return (
    <Modal>
      <Modal.Trigger>
        <div>{trigger}</div>
      </Modal.Trigger>
      <Modal.Content>
        <Modal.Header className="text-center">
          <Modal.CloseButton />
          {title && (
            <Heading variant="title-three" className="mb-2">
              {title}
            </Heading>
          )}
          {description && <Text variant="body-sm">{description}</Text>}
        </Modal.Header>
        <Modal.Body className="my-6">
          <LoginForm onSuccess={onSuccess} redirect={redirect} />
        </Modal.Body>
      </Modal.Content>
    </Modal>
  )
}
