import classNames from 'classnames'
import { FC, HTMLAttributes } from 'react'

import { CloseIconButton } from '../CloseIconButton'
import { usePopoverContext } from '../Popover'

export const Header: FC<HTMLAttributes<HTMLDivElement>> = ({
  className,
  children,
  ...props
}) => {
  const { onClose } = usePopoverContext()

  return (
    <header
      className={classNames(
        'relative flex items-center justify-between px-10 py-8 border-b',
        className,
      )}
      {...props}
    >
      {children}
      <CloseIconButton
        className={classNames('!bg-[#F0F0F0] self-start', className)}
        onClick={onClose}
        aria-label="Close"
      />
    </header>
  )
}
