import classNames from 'classnames'
import { hexToHSL } from '../../utilities'
import type { Swatch, SwatchProps } from './types'
import { ElementType, Component } from 'react'

const lightColorDiscriminator = hexToHSL('#e9e9e9')

const calculateGradient = (hexes: string[] = []) =>
  hexes
    .map(
      (hex, index) =>
        `${hex} ${(index * 100) / hexes.length}%, ${hex} ${
          ((index + 1) * 100) / hexes.length
        }%`,
    )
    .join(', ')

const calculateIsLightColor = (hexes: string[] = []) =>
  hexes.some((hex) => {
    const hsl = hexToHSL(hex)
    return (
      hsl.lightness > lightColorDiscriminator.lightness && hsl.saturation < 20
    )
  })

const defaultElement = 'button'

const sizeMap = {
  sm: 'w-4 h-4',
  md: 'w-6 h-6',
}

const Swatch = <E extends ElementType = typeof defaultElement>({
  as,
  className,
  swatch,
  hexes,
  isSelected,
  onClick,
  size = 'md',
  ...props
}: SwatchProps<E>): JSX.Element => {
  const Component = as ?? (defaultElement as ElementType)
  const isLightColor = calculateIsLightColor(hexes)

  return (
    <Component
      onClick={onClick}
      className={classNames('h-full', className)}
      title={swatch.color}
      {...props}
    >
      <span
        className={classNames(
          'block relative overflow-hidden',
          isSelected &&
            (size === 'sm'
              ? 'shadow-[0_0_0_1px_#b5b5b5_inset,0_0_0_2px_#fff_inset]'
              : 'shadow-[0_0_0_1px_#b5b5b5_inset,0_0_0_3px_#fff_inset]'),
          isLightColor && !isSelected && 'shadow-[0_0_0_1px_#e9e9e9_inset]',
          sizeMap[size],
        )}
        style={{
          background:
            hexes.length > 1
              ? `linear-gradient(45deg, ${calculateGradient(hexes)})`
              : hexes[0],
        }}
      />
    </Component>
  )
}

export { Swatch }
