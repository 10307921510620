import classNames from 'classnames'
import { forwardRef } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

import { Popover, usePopoverContext } from '../Popover'
import { Content as PopoverContent } from '../Popover/Content'
import { Portal } from '../Portal'

import { ActionSheetProps } from './types'

export const Content: React.FC<ActionSheetProps> = forwardRef<
  HTMLDivElement,
  ActionSheetProps
>(({ children, className, dismissDragOffset = 100, ...props }, ref) => {
  const { isOpen } = usePopoverContext()

  return (
    <Portal>
      <Popover.Backdrop className="z-50" />
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ x: '100%' }}
            animate={{ x: '0%' }}
            exit={{ x: '100%' }}
            transition={{
              duration: 0.33,
            }}
            className="fixed right-0 top-0 bottom-0 z-[60] w-full max-w-[496px] flex justify-center pointer-events-none"
          >
            <PopoverContent
              className={classNames(
                'flex flex-col bg-white w-full pointer-events-auto relative',
                className,
              )}
              ref={ref}
              {...props}
            >
              {children}
            </PopoverContent>
          </motion.div>
        )}
      </AnimatePresence>
    </Portal>
  )
})

Content.displayName = 'ActionSheetContent'
