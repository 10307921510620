import classNames from 'classnames'
import { MouseEventHandler, useCallback } from 'react'

import { Icon, IconButton } from '../../components'

import { FavouriteButtonProps } from './types'

export const FavouriteButton = ({
  isFavourited,
  onChange,
  ...props
}: FavouriteButtonProps) => {
  const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (evt) => {
      evt.preventDefault()
      onChange(!isFavourited)
    },
    [isFavourited],
  )

  return (
    <button
      size="md"
      className={classNames(
        'product-card__favourite',
        isFavourited
          ? 'product-card__favourite--on'
          : 'product-card__favourite--off',
      )}
      onClick={handleClick}
      aria-label={
        isFavourited
          ? 'Remove product from favourites'
          : 'Add product to favourites'
      }
      {...props}
    >
      <Icon.Heart />
    </button>
  )
}
