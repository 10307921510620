import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { Badge, StandardProductCardProps } from '../types'
import { useDataTranslation } from '@/features/localisation/hooks/useDataTranslation/useDataTranslation'

interface ProductBadges {
  primaryBadge: Badge | null
  secondaryBadge: Badge | null
}

export const useProductBadges = ({
  badges = [],
  price,
  price_compare_at,
  tags = [],
}: Pick<
  StandardProductCardProps['product'],
  'badges' | 'price' | 'price_compare_at' | 'tags'
>): ProductBadges => {
  const { t } = useTranslation()
  const { isLoading: isDataTranslationLoading, t: dataT } = useDataTranslation()

  const primaryBadge = useMemo(() => {
    const priceNumber = Number(price)
    const compareAtPriceNumber = Number(price_compare_at)

    if (compareAtPriceNumber && compareAtPriceNumber > priceNumber) {
      const discount = Math.round(
        ((compareAtPriceNumber - priceNumber) / compareAtPriceNumber) * 100
      )

      return {
        text:
          discount === 100
            ? t('product.free')
            : t('product.discount', { discount }),
        color: 'critical' as Badge['color'],
        size: 'sm' as Badge['size'],
      }
    } else if (tags.includes('new')) {
      return {
        text: t('general.new'),
        color: 'primary' as Badge['color'],
        size: 'sm' as Badge['size'],
      }
    }

    return null
  }, [price, price_compare_at, tags])

  const secondaryBadge = useMemo(() => {
    if (badges.length > 0 && !isDataTranslationLoading) {
      return {
        text: dataT(badges[0]),
        variant:'outline' as Badge['variant'],        
        size: 'sm' as Badge['size'],
        color: 'primary' as Badge['color'],
      }
    }

    return null
  }, [badges, isDataTranslationLoading])

  return { primaryBadge, secondaryBadge }
}
