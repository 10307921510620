const index = "";
function getDefaultExportFromCjs(x) {
  return x && x.__esModule && Object.prototype.hasOwnProperty.call(x, "default") ? x["default"] : x;
}
var createPlugin$2 = {};
var createPlugin$1 = {};
(function(exports) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
      return _default;
    }
  });
  function createPlugin2(plugin2, config2) {
    return {
      handler: plugin2,
      config: config2
    };
  }
  createPlugin2.withOptions = function(pluginFunction, configFunction = () => ({})) {
    const optionsFunction = function(options) {
      return {
        __options: options,
        handler: pluginFunction(options),
        config: configFunction(options)
      };
    };
    optionsFunction.__isOptionsFunction = true;
    optionsFunction.__pluginFunction = pluginFunction;
    optionsFunction.__configFunction = configFunction;
    return optionsFunction;
  };
  const _default = createPlugin2;
})(createPlugin$1);
(function(exports) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
      return _default;
    }
  });
  const _createPlugin = /* @__PURE__ */ _interop_require_default(createPlugin$1);
  function _interop_require_default(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }
  const _default = _createPlugin.default;
})(createPlugin$2);
let createPlugin = createPlugin$2;
var plugin$1 = (createPlugin.__esModule ? createPlugin : { default: createPlugin }).default;
const plugin$2 = /* @__PURE__ */ getDefaultExportFromCjs(plugin$1);
const plugin = plugin$1;
const baseStyles = {
  overflow: "hidden",
  display: "-webkit-box",
  "-webkit-box-orient": "vertical"
};
const lineClamp = plugin(
  function({ matchUtilities, addUtilities, theme: theme2, variants }) {
    const values = theme2("lineClamp");
    matchUtilities(
      {
        "line-clamp": (value) => ({
          ...baseStyles,
          "-webkit-line-clamp": `${value}`
        })
      },
      { values }
    );
    addUtilities(
      [
        {
          ".line-clamp-none": {
            "-webkit-line-clamp": "unset"
          }
        }
      ],
      variants("lineClamp")
    );
  },
  {
    theme: {
      lineClamp: {
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
        6: "6"
      }
    },
    variants: {
      lineClamp: ["responsive"]
    }
  }
);
var src = lineClamp;
const lineClamp$1 = /* @__PURE__ */ getDefaultExportFromCjs(src);
const tokens = {
  "breakpoint": {
    "xs": "370px",
    "sm": "576px",
    "md": "852px",
    "lg": "968px",
    "xl": "1080px",
    "2xl": "1200px",
    "3xl": "1540px"
  },
  "color": {
    "action": {
      "primary": {
        "default": "#111111",
        "hovered": "#7d7d7d",
        "pressed": "#e5e5e5",
        "disabled": "#e5e5e5"
      },
      "secondary": {
        "default": "#ffffff",
        "hovered": "#c8c8c8",
        "pressed": "#e5e5e5",
        "disabled": "#e5e5e5"
      },
      "critical": {
        "default": "#d82c0d",
        "hovered": "#ca2b0f",
        "pressed": "#ad240c",
        "disabled": "#e5e5e5"
      }
    },
    "background": {
      "primary": {
        "default": "#f5f5f5",
        "hovered": "#f0f0f0",
        "pressed": "#e5e5e5",
        "selected": "#c8c8c8"
      },
      "secondary": {
        "default": "#f7f8f5",
        "hovered": "#f0f1ed",
        "pressed": "#dfe2d6",
        "selected": "#e3e8d9"
      },
      "tertiary": {
        "default": "#fbf7ee",
        "hovered": "#f4ead3",
        "pressed": "#eee0bf",
        "selected": "#f7f2e5"
      }
    },
    "base": {
      "primary": "#111111",
      "secondary": "#ffffff",
      "interactive": "#2e72d2",
      "critical": "#d82c0d",
      "warning": "#d8a000",
      "success": "#008060"
    },
    "border": {
      "default": "#e5e5e5",
      "hovered": "#7d7d7d",
      "pressed": "#111111",
      "disabled": "#e5e5e5",
      "critical": {
        "default": "#d82c0d",
        "disabled": "#efab9e"
      },
      "success": {
        "default": "#000000"
      },
      "highlight": {
        "default": "#2e72d2"
      }
    },
    "decorative": {
      "fyb": {
        "one": "#e9c7c5",
        "two": "#efe7e5",
        "three": "#181f27",
        "four": "#e5e5e5"
      },
      "sustainability": {
        "one": "#1d4b40",
        "two": "#335c53",
        "three": "#738f88",
        "four": "#b5c2bf",
        "five": "#cad3d1",
        "six": "#e0e4e3"
      },
      "swatches": {
        "black": "#111111",
        "red": "#b4321a",
        "blue": "#2f74b7",
        "white": "#ffffff",
        "green": "#205005",
        "gray": "#c8c8c8",
        "cream": "#eae6e3",
        "brown": "#88594e",
        "yellow": "#f0e49c",
        "orange": "#f5bb6a",
        "purple": "#c285e0",
        "pink": "#ec6190"
      }
    },
    "icon": {
      "default": "#111111",
      "subdued": "#7d7d7d",
      "hovered": "#1a1c1d",
      "pressed": "#e5e5e5",
      "disabled": "#e5e5e5",
      "critical": "#d82c0d",
      "warning": "#b98900",
      "success": "#008060",
      "highlight": "#2e72d2",
      "on": {
        "default": "#ffffff",
        "primary": "#ffffff",
        "critical": "#ffffff"
      }
    },
    "shadow": "rgba(17, 17, 17, 0.15)",
    "surface": {
      "primary": "#e7e7e7",
      "secondary": "#f7f8f5",
      "tertiary": "#fbf7ee"
    },
    "text": {
      "default": "#111111",
      "disabled": "#e5e5e5",
      "on": {
        "default": "#ffffff",
        "critical": "#ffffff"
      },
      "critical": "#d72c0d",
      "warning": "#d8a000",
      "success": "#008060"
    }
  },
  "elevation": {
    "1": {
      "x": 0,
      "y": 2,
      "color": "rgba(17, 17, 17, 0.15)",
      "blur": 4,
      "spread": 0,
      "type": "dropShadow"
    },
    "2": {
      "x": 0,
      "y": 4,
      "color": "rgba(17, 17, 17, 0.15)",
      "blur": 8,
      "spread": 0,
      "type": "dropShadow"
    },
    "3": {
      "x": 0,
      "y": 8,
      "color": "rgba(17, 17, 17, 0.15)",
      "blur": 16,
      "spread": 0,
      "type": "dropShadow"
    },
    "4": {
      "x": 0,
      "y": 16,
      "color": "rgba(17, 17, 17, 0.15)",
      "blur": 32,
      "spread": 0,
      "type": "dropShadow"
    }
  },
  "size": {
    "radii": {
      "xs": "4px",
      "sm": "8px",
      "md": "16px",
      "lg": "24px",
      "circle": "1000rem"
    },
    "space": {
      "0": "0px",
      "1": "4px",
      "2": "8px",
      "3": "12px",
      "4": "16px",
      "5": "20px",
      "6": "24px",
      "7": "28px",
      "8": "32px",
      "9": "36px",
      "10": "40px",
      "11": "44px",
      "12": "48px",
      "13": "52px",
      "14": "56px",
      "15": "60px",
      "16": "64px",
      "17": "68px",
      "18": "72px",
      "19": "76px",
      "20": "80px"
    }
  },
  "font-family": {
    "base": "'Montserrat', sans-serif",
    "didot": "'Didot', serif"
  },
  "font-size": {
    "xs": "10px",
    "sm": "12px",
    "md": "14px",
    "lg": "16px",
    "xl": "18px",
    "2xl": "20px",
    "3xl": "24px",
    "4xl": "28px",
    "5xl": "32px",
    "6xl": "36px",
    "7xl": "40px",
    "8xl": "48px",
    "9xl": "56px",
    "button": "12px"
  },
  "font-weight": {
    "regular": 400,
    "medium": 500,
    "semi-bold": 600,
    "bold": 700
  },
  "letter-spacing": {
    "md": "0.02rem",
    "lg": "0.04rem"
  },
  "line-height": {
    "xs": "16px",
    "sm": "20px",
    "md": "24px",
    "lg": "28px",
    "xl": "32px",
    "2xl": "40px",
    "3xl": "48px",
    "4xl": "64px",
    "5xl": "72px",
    "button": "16px"
  },
  "typography": {
    "display-one": {
      "fontFamily": "'Montserrat', sans-serif",
      "fontWeight": 700,
      "lineHeight": "72px",
      "fontSize": "56px",
      "letterSpacing": "0.02rem"
    },
    "heading-one": {
      "fontFamily": "'Montserrat', sans-serif",
      "fontWeight": 500,
      "fontSize": "36px",
      "lineHeight": "48px",
      "letterSpacing": "0.04rem"
    },
    "heading-two": {
      "fontFamily": "'Montserrat', sans-serif",
      "fontWeight": 500,
      "fontSize": "32px",
      "lineHeight": "40px",
      "letterSpacing": "0.04rem"
    },
    "heading-three": {
      "fontFamily": "'Montserrat', sans-serif",
      "fontWeight": 500,
      "fontSize": "28px",
      "lineHeight": "40px",
      "letterSpacing": "0.04rem"
    },
    "heading-four": {
      "fontFamily": "'Montserrat', sans-serif",
      "fontWeight": 500,
      "fontSize": "24px",
      "lineHeight": "40px",
      "letterSpacing": "0.04rem"
    },
    "heading-five": {
      "fontFamily": "'Montserrat', sans-serif",
      "fontWeight": 500,
      "fontSize": "18px",
      "lineHeight": "24px",
      "letterSpacing": "0.04rem"
    },
    "heading-six": {
      "fontFamily": "'Montserrat', sans-serif",
      "fontWeight": 500,
      "fontSize": "16px",
      "lineHeight": "24px",
      "letterSpacing": "0.04rem"
    },
    "title-one": {
      "fontFamily": "'Montserrat', sans-serif",
      "fontWeight": 500,
      "fontSize": "36px",
      "lineHeight": "48px",
      "letterSpacing": "0.02rem"
    },
    "title-two": {
      "fontFamily": "'Montserrat', sans-serif",
      "fontWeight": 500,
      "fontSize": "28px",
      "lineHeight": "40px",
      "letterSpacing": "0.02rem"
    },
    "title-three": {
      "fontFamily": "'Montserrat', sans-serif",
      "fontWeight": 500,
      "fontSize": "24px",
      "lineHeight": "32px",
      "letterSpacing": "0.02rem"
    },
    "title-four": {
      "fontFamily": "'Montserrat', sans-serif",
      "fontWeight": 500,
      "fontSize": "20px",
      "lineHeight": "40px",
      "letterSpacing": "0.02rem"
    },
    "title-five": {
      "fontFamily": "'Montserrat', sans-serif",
      "fontWeight": 500,
      "fontSize": "18px",
      "lineHeight": "28px",
      "letterSpacing": "0.02rem"
    },
    "title-six": {
      "fontFamily": "'Montserrat', sans-serif",
      "fontWeight": 500,
      "fontSize": "16px",
      "lineHeight": "24px",
      "letterSpacing": "0.02rem"
    },
    "body-lg": {
      "fontFamily": "'Montserrat', sans-serif",
      "fontWeight": 400,
      "fontSize": "16px",
      "lineHeight": "24px",
      "letterSpacing": "0.02rem"
    },
    "body-md": {
      "fontFamily": "'Montserrat', sans-serif",
      "fontWeight": 400,
      "fontSize": "14px",
      "lineHeight": "24px",
      "letterSpacing": "0.02rem"
    },
    "body-sm": {
      "fontFamily": "'Montserrat', sans-serif",
      "fontWeight": 400,
      "fontSize": "12px",
      "lineHeight": "20px",
      "letterSpacing": "0.02rem"
    },
    "button": {
      "fontFamily": "'Montserrat', sans-serif",
      "fontWeight": 500,
      "fontSize": "12px",
      "lineHeight": "16px",
      "letterSpacing": "0.02rem"
    }
  },
  "z-index": {
    "0": "0",
    "1": "10",
    "2": "20",
    "3": "30",
    "4": "40",
    "5": "50",
    "6": "60",
    "7": "70",
    "8": "80",
    "9": "90",
    "10": "100"
  }
};
const tokensV2 = {
  "breakpoint": {
    "xs": "23.125rem",
    "sm": "36rem",
    "md": "53.25rem",
    "lg": "60.5rem",
    "xl": "67.5rem",
    "2xl": "75rem",
    "3xl": "96.25rem"
  },
  "background": {
    "primary": "#ffffff",
    "secondary": "#f7f8f5",
    "tertiary": "#fbf7ee"
  },
  "border": {
    "primary": {
      "default": "#d1d1d1",
      "hover": "#b0b0b0",
      "active": "#3d3d3d",
      "disabled": "#e7e7e7"
    },
    "critical": {
      "default": "#ffd0c8",
      "hover": "#fe816b",
      "active": "#f7583c",
      "disabled": "#e7e7e7"
    }
  },
  "icon": {
    "default": "#3d3d3d",
    "subtle": "#4f4f4f",
    "disabled": "#b0b0b0",
    "inverse": "#ffffff",
    "critical": "#ffd0c8"
  },
  "color": {
    "caramel": {
      "50": "#fbf7ee",
      "100": "#f7f0dd",
      "200": "#eeddba",
      "300": "#e3c58e",
      "400": "#d7a660",
      "500": "#ce8e41",
      "600": "#c07836",
      "700": "#9f5e2f",
      "800": "#804c2c",
      "900": "#684026",
      "950": "#381f12"
    },
    "green": {
      "50": "#ebfef6",
      "100": "#d0fbe6",
      "200": "#a4f6d3",
      "300": "#6aebbc",
      "400": "#2fd8a0",
      "500": "#0abf89",
      "600": "#009b70",
      "700": "#008060",
      "800": "#03624a",
      "900": "#04503f",
      "950": "#012d25"
    },
    "yellow": {
      "50": "#fefde8",
      "100": "#fffec2",
      "200": "#fffa88",
      "300": "#ffef43",
      "400": "#ffde10",
      "500": "#efc403",
      "600": "#d8a000",
      "700": "#a46d04",
      "800": "#87550c",
      "900": "#734510",
      "950": "#432405"
    },
    "sage": {
      "50": "#f7f8f5",
      "100": "#ebeee6",
      "200": "#d6dbcd",
      "300": "#bec5ac",
      "400": "#a4ac8b",
      "500": "#949c73",
      "600": "#8a8f67",
      "700": "#757757",
      "800": "#61624a",
      "900": "#50503e",
      "950": "#2a2a20"
    },
    "blue": {
      "50": "#f0f8fe",
      "100": "#deeefb",
      "200": "#c4e4f9",
      "300": "#9bd3f5",
      "400": "#6cbaee",
      "500": "#499de8",
      "600": "#3483dc",
      "700": "#2e72d2",
      "800": "#2959a4",
      "900": "#264c82",
      "950": "#1c304f"
    },
    "red": {
      "50": "#fff3f1",
      "100": "#ffe6e1",
      "200": "#ffd0c8",
      "300": "#ffafa1",
      "400": "#fe816b",
      "500": "#f7583c",
      "600": "#e43c1e",
      "700": "#c12f15",
      "800": "#9f2a15",
      "900": "#832919",
      "950": "#481107"
    },
    "neutral": {
      "0": "#ffffff",
      "50": "#f6f6f6",
      "100": "#e7e7e7",
      "200": "#d1d1d1",
      "300": "#b0b0b0",
      "400": "#888888",
      "500": "#6d6d6d",
      "600": "#5d5d5d",
      "700": "#4f4f4f",
      "800": "#454545",
      "900": "#3d3d3d",
      "950": "#111111",
      "1000": "#000000"
    },
    "alpha-black": {
      "50": "rgba(0, 0, 0, 0.02)",
      "100": "rgba(0, 0, 0, 0.04)",
      "200": "rgba(0, 0, 0, 0.08)",
      "300": "rgba(0, 0, 0, 0.12)",
      "400": "rgba(0, 0, 0, 0.16)",
      "500": "rgba(0, 0, 0, 0.24)",
      "600": "rgba(0, 0, 0, 0.32)",
      "700": "rgba(0, 0, 0, 0.48)",
      "800": "rgba(0, 0, 0, 0.64)",
      "900": "rgba(0, 0, 0, 0.8)"
    }
  },
  "surface": {
    "primary": {
      "default": "#ffffff",
      "hover": "#d1d1d1",
      "active": "#ffffff",
      "disabled": "#e7e7e7"
    },
    "secondary": {
      "default": "#f7f8f5",
      "hover": "#ebeee6",
      "secondary": {
        "active": "#d6dbcd",
        "disabled": "#ebeee6"
      },
      "tertiary": {
        "default": "#fbf7ee",
        "hover": "#f7f0dd",
        "active": "#eeddba",
        "disbaled": "#f7f0dd"
      }
    },
    "critifcal": {
      "default": "#ffd0c8",
      "hover": "#ffafa1",
      "active": "#fe816b",
      "disabled": "#ffe6e1"
    }
  },
  "text": {
    "default": "#3d3d3d",
    "subtle": "#4f4f4f",
    "disabled": "#b0b0b0",
    "inverse": "#ffffff",
    "critical": "#ffd0c8"
  },
  "radius": {
    "circle": "62.5rem"
  },
  "spacing": {
    "0": "0",
    "1": "0.25rem",
    "2": "0.5rem",
    "3": "0.75rem",
    "4": "1rem",
    "5": "1.25rem",
    "6": "1.5rem",
    "7": "1.75rem",
    "8": "2rem",
    "9": "2.25rem",
    "10": "2.5rem",
    "11": "2.75rem",
    "12": "3rem",
    "13": "3.25rem",
    "14": "3.5rem",
    "15": "3.75rem",
    "16": "4rem",
    "17": "4.25rem",
    "18": "4.5rem",
    "19": "4.75rem",
    "20": "5rem"
  },
  "transition": {
    "default": {
      "duration": "0.3s",
      "delay": "0s",
      "timingFunction": [
        0.4,
        0,
        0.2,
        1
      ]
    },
    "cubic-bezier": {
      "duration": "0.3s",
      "delay": "0s",
      "timingFunction": [
        0.4,
        0,
        0.2,
        1
      ]
    }
  },
  "font-family": {
    "base": "'Inter Tight', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif"
  },
  "font-size": {
    "xs": "0.625rem",
    "sm": "0.75rem",
    "md": "0.875rem",
    "lg": "1rem",
    "xl": "1.125rem",
    "2xl": "1.25rem",
    "3xl": "1.5rem",
    "4xl": "1.75rem",
    "5xl": "2rem",
    "6xl": "2.25rem",
    "7xl": "2.5rem"
  },
  "font-weight": {
    "light": 300,
    "regular": 400,
    "medium": 500,
    "semi-bold": 600,
    "bold": 700
  },
  "letter-spacing": {
    "none": "0",
    "md": "0.00125rem",
    "lg": "0.0025rem"
  },
  "line-height": {
    "xs": "1rem",
    "sm": "1.25rem",
    "md": "1.5rem",
    "lg": "1.75rem",
    "xl": "2rem",
    "2xl": "2.5rem",
    "3xl": "3rem",
    "4xl": "4rem",
    "5xl": "4.5rem",
    "button": "1rem"
  },
  "typography": {
    "title-one": {
      "fontFamily": "'Inter Tight', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
      "fontWeight": 400,
      "fontSize": "2rem",
      "lineHeight": "2.5rem",
      "letterSpacing": "0"
    },
    "title-two": {
      "fontFamily": "'Inter Tight', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
      "fontWeight": 400,
      "fontSize": "1.75rem",
      "lineHeight": "2rem",
      "letterSpacing": "0"
    },
    "title-three": {
      "fontFamily": "'Inter Tight', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
      "fontWeight": 400,
      "fontSize": "1.5rem",
      "lineHeight": "1.75rem",
      "letterSpacing": "0"
    },
    "title-four": {
      "fontFamily": "'Inter Tight', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
      "fontWeight": 400,
      "lineHeight": "1.5rem",
      "fontSize": "1.125rem",
      "letterSpacing": "0"
    },
    "title-five": {
      "fontFamily": "'Inter Tight', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
      "fontWeight": 400,
      "lineHeight": "1.25rem",
      "fontSize": "1rem",
      "letterSpacing": "0"
    },
    "title-six": {
      "fontFamily": "'Inter Tight', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
      "fontWeight": 400,
      "lineHeight": "1rem",
      "fontSize": "0.875rem",
      "letterSpacing": "0"
    },
    "body-xl": {
      "fontFamily": "'Inter Tight', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
      "fontWeight": 400,
      "fontSize": "1.125rem",
      "lineHeight": "1.5rem",
      "letterSpacing": "0"
    },
    "body-lg": {
      "fontFamily": "'Inter Tight', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
      "fontWeight": 400,
      "fontSize": "1rem",
      "lineHeight": "1.5rem",
      "letterSpacing": "0"
    },
    "body-md": {
      "fontFamily": "'Inter Tight', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
      "fontWeight": 400,
      "fontSize": "0.875rem",
      "lineHeight": "1.5rem",
      "letterSpacing": "0"
    },
    "body-sm": {
      "fontFamily": "'Inter Tight', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
      "fontWeight": 400,
      "fontSize": "0.75rem",
      "lineHeight": "1.25rem",
      "letterSpacing": "0"
    }
  },
  "z-index": {
    "0": {
      "original": {},
      "attributes": {},
      "path": {}
    },
    "1": 10,
    "2": 20,
    "3": 30,
    "4": 40,
    "5": 50,
    "6": 60,
    "7": 70,
    "8": 80,
    "9": 90,
    "10": 100
  }
};
const normaliseSizeValue = (value, unit = "px") => {
  if (typeof value === "number") {
    return `${value}px`;
  }
  return /^\+?(0|[1-9]\d*)$/.test(value) ? `${value}${unit}` : value;
};
const createShadows = (elevation) => Object.entries(elevation).reduce(
  (prev, [key, { x = 0, y = 0, blur = 0, spread = 0, color: color2 = "black" }]) => ({
    ...prev,
    [key]: [
      normaliseSizeValue(x),
      normaliseSizeValue(y),
      normaliseSizeValue(blur),
      normaliseSizeValue(spread),
      color2
    ].join(" ")
  }),
  {}
);
const theme = {
  spacing: tokens.size.space,
  fontFamily: tokensV2["font-family"],
  fontSize: tokensV2["font-size"],
  fontWeight: Object.entries(tokensV2["font-weight"]).reduce(
    (prev, [key, value]) => ({ ...prev, [key]: value.toString() }),
    {}
  ),
  lineHeight: tokensV2["line-height"],
  letterSpacing: tokensV2["letter-spacing"],
  borderRadius: tokens.size.radii,
  color: tokens.color,
  screens: tokens.breakpoint,
  boxShadow: createShadows(tokens.elevation)
};
const { color, ...baseThemeProperties } = theme;
const config = {
  content: ["src/**/*.{js,jsx,ts,tsx}", "../../packages/**/*.{js,ts,jsx,tsx}"],
  darkMode: "class",
  theme: {
    ...baseThemeProperties,
    extend: {
      colors: {
        ...color,
        facebook: "#1778F2",
        twitter: "#1DA1F2",
        pintrest: "#E60023"
      }
    }
  },
  plugins: [
    lineClamp$1,
    plugin$2(function({ addVariant }) {
      const prefix = "ui";
      for (const state of [
        "open",
        "checked",
        "selected",
        "active",
        "disabled"
      ]) {
        addVariant(`${prefix}-${state}`, [
          `&[data-ui-state~="${state}"]`,
          `:where([data-ui-state~="${state}"]) &`
        ]);
        addVariant(`${prefix}-not-${state}`, [
          `&[data-ui-state]:not([data-ui-state~="${state}"])`,
          `:where([data-ui-state]:not([data-ui-state~="${state}"])) &:not([data-ui-state])`
        ]);
      }
    })
  ]
};
export {
  config,
  theme
};
