import { useCurrentStore } from '@/features/localisation/hooks/useCurrentStore'
import { STORES, Store } from '@/features/localisation/stores'
import { Modal, Text } from '@lounge-fe/ui-kit'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useUserStoreCookie } from '../../hooks/useUserStoreCookie'

export const Content = ({ stores = STORES }) => {
  const currentStore = useCurrentStore()
  const { t } = useTranslation()
  const [, setUserStoreCookie] = useUserStoreCookie()

  return (
    <Modal.Content size="xl">
      <Modal.Header>
        <Modal.CloseButton />
        <Text variant="title-five">{t('action.select_your_location')}</Text>
      </Modal.Header>
      <Modal.Body>
        <ul className="grid grid-cols-[repeat(auto-fit,minmax(250px,1fr))] gap-3">
          {[...stores]
            .sort((a, b) => {
              const isCurrentStore = (store: Store) =>
                store.shortName === currentStore.shortName
              const isRestOfWorld = (store: Store) => store.shortName === 'ROW'

              // Prioritise current store to be first
              if (isCurrentStore(a) || isRestOfWorld(b)) return -1

              // Prioritise 'Rest of World' to be last
              if (isCurrentStore(b) || isRestOfWorld(a)) return 1

              // If neither store is the current store or 'Rest of World', sort remaining alphabetically
              return a.name.localeCompare(b.name)
            })
            .map(
              ({ name, shop, domain, icon: FlagIcon, language, shortName }) => (
                <li key={shop}>
                  <a
                    key={shop}
                    onClick={() => setUserStoreCookie(shop)}
                    href={`${domain}?action=clear-locale`}
                    className={classNames(
                      'flex gap-x-3 p-4 rounded-sm border-0 text-left hover:bg-background-primary-default',
                      shop === currentStore.shop &&
                        'bg-background-primary-default',
                    )}
                  >
                    <FlagIcon className="w-6 h-6 rounded-circle" />
                    <div>
                      <Text variant="body-sm" className="font-regular">
                        {name}
                      </Text>
                      <Text variant="body-sm">{language}</Text>
                    </div>
                  </a>
                </li>
              ),
            )}
        </ul>
      </Modal.Body>
    </Modal.Content>
  )
}
