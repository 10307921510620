const useMergedRefs = <T>(...refs: React.Ref<T>[]) => {
  return (instance: T | null) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(instance)
      } else if (ref) {
        ;(ref as React.MutableRefObject<T | null>).current = instance
      }
    })
  }
}

export { useMergedRefs }
