import { useMutation } from '@tanstack/react-query'
import { useCartContext } from '../components'
import { useRecentlyAddedProducts } from './useRecentlyAddedProducts'
import { AddCartItemPayload, Cart } from '../types'

export const useAddCartItems = (args?: {
  onSuccess?: (data: Cart, variables: AddCartItemPayload[]) => void
}) => {
  const { onSuccess } = args ?? {}
  const setRecentlyAddedProductIds = useRecentlyAddedProducts(
    ({ setRecentlyAddedProductIds }) => setRecentlyAddedProductIds,
  )

  const { cartManager, recentlyAddedTimer } = useCartContext()

  return useMutation({
    mutationFn: cartManager.addItems,
    onMutate: () => {
      setRecentlyAddedProductIds([])
    },
    onSuccess: onSuccess
      ? onSuccess
      : (_, variables) => {
          setRecentlyAddedProductIds(variables.map((item) => item.id))
          recentlyAddedTimer.start()
        },
  })
}
