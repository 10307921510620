import { Search } from '../Search'
import { useSearchContext } from '../Navigation/SearchProvider'
import { RecommendationProps } from './types'
import { Text } from '@lounge-fe/ui-kit'
import { useTranslation } from 'react-i18next'

export const Recommendations = ({
  suggestedSearchTerms,
}: RecommendationProps) => {
  const { t } = useTranslation()
  const { setSearch } = useSearchContext()

  if (!Boolean(suggestedSearchTerms?.length)) return

  return (
    <>
      <Text as="h2" className="font-regular mb-2">
        {t('general.search_suggestions')}
      </Text>
      <div className="flex flex-col gap-1 items-start">
        {suggestedSearchTerms.map((term) => {
          return (
            <Search.MenuItem
              key={term}
              onClick={() => setSearch(term.toLowerCase())}
            >
              {term}
            </Search.MenuItem>
          )
        })}
      </div>
    </>
  )
}
