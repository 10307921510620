import { Text } from '../../components'

import { CaptionImageCaption } from './types'

export const Caption = ({ caption }: CaptionImageCaption) => (
  <div className="captioned-image__caption">
    <Text variant="body-sm" className="font-regular">
      {caption}
    </Text>
  </div>
)
