import * as Sentry from '@sentry/react'
import { create } from 'zustand'
import { useEffect } from 'react'

import { storefrontClient } from '@/lib'
import { getColors } from './query'
import { ColorState, ColorMetaobject, GetColorResponse } from './types'
import { normaliseProductColor } from '@lounge-fe/ui-kit/src/utilities/normaliseProductColor'

const requestColors = async () => {
  const response = await storefrontClient.request<GetColorResponse>(getColors)
  const data = await response?.data?.metaobjects.nodes

  return data
}

const useColorStore = create<ColorState>((set, get) => ({
  colors: {},
  loaded: false,
  fetchColors: async () => {
    const currentState = get()

    if (currentState.loaded) return

    set({ loaded: true })
    try {
      const response = await requestColors() // Assume requestColors is imported
      const newColors: Record<string, string[]> = (response || []).reduce(
        (acc: Record<string, string[]>, color: ColorMetaobject) => {
          const handle: string = normaliseProductColor(
            color.title?.value ?? color.handle,
          )
          acc[handle] = JSON.parse(color.color?.value ?? '[]')
          return acc
        },
        {},
      )

      set({ colors: newColors })
    } catch (error) {
      Sentry.captureException(error)
      set({ loaded: false })
    }
  },
}))

export const useColors = () => {
  const { colors, fetchColors, loaded } = useColorStore((state) => state)
  useEffect(() => {
    fetchColors()
  }, [fetchColors])

  return { colors, hasLoaded: loaded }
}
