import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { motion } from 'framer-motion'

import {
  Logo,
  Wrapper,
  Flex,
  Grid,
  IconButton,
  Icon,
  Transition,
  Portal,
  Backdrop,
  Popover,
} from '@lounge-fe/ui-kit'
import { NavigationProps } from '../types'
import { useShopify } from '../../../../../hooks/useShopify'
import { useScrollAnimation } from '../../../hooks/useScrollAnimation'
import { useSearchContext } from '../SearchProvider'
import { useNavigationContext } from '../Navigation'

import { SearchForm } from './SearchForm'
import { AccountMenu } from './AccountMenu'
import { MainMenu } from './MainMenu'
import { useRef, useState } from 'react'
import { WishlistButton } from './WishlistButton'

export const Navigation = ({ children, className }: NavigationProps) => {
  const { t } = useTranslation()
  const { routes } = useShopify()
  const [isMainMenuOpen, setIsMainMenuOpen] = useState(false)

  const searchInputRef = useRef<HTMLInputElement | null>(null)

  const scrollAnimationProps = useScrollAnimation()
  const { hasScrollAnimation, searchComponent, basketComponent } =
    useNavigationContext()

  const searchContext = useSearchContext()
  return (
    <Popover shards={[searchInputRef]}>
      {({ isOpen }) => (
        <div
          className={classNames(
            'desktop-navigation',
            isOpen && 'desktop-navigation--search-active',
            isMainMenuOpen && 'desktop-navigation--menu-active',
            className,
          )}
        >
          <div className="h-[var(--header-height)] desktop-navigation__spacer" />
          <motion.header
            className={classNames(
              'bg-base-secondary',
              'dark:bg-base-primary',
              'fixed',
              'top-0',
              'z-40',
              'left-0',
              'right-0',
              'w-screen',
              'h-[calc(var(--header-height)_-_1px)]',
              'after:content-empty after:absolute after:left-0 after:right-0 after:h-[1px] after:z-50 after:bg-[#F0F0F0] after:pointer-events-none',
            )}
            {...{
              ...(hasScrollAnimation.desktop ? scrollAnimationProps : {}),
            }}
          >
            <Wrapper className="h-full max-w-full bg-base-secondary dark:bg-base-primary desktop-navigation__wrapper">
              <Flex className="justify-center h-full">
                <Flex
                  as="a"
                  href={routes.root_url}
                  title="Lounge Underwear"
                  className="flex-1 align-center"
                >
                  <Logo className="desktop-navigation__logo" />
                </Flex>
                <Grid className="items-center grid-cols-1 flex-[3] [&_>_*]:row-start-1 [&_>_*]:col-start-1">
                  <Transition.Fade
                    data-testid="search-transition"
                    className="flex items-center justify-center w-full h-full flex-[2_1_auto]"
                    show={isOpen}
                    onAnimationEnd={() => {
                      if (isOpen) {
                        searchInputRef.current?.focus()
                      }
                    }}
                  >
                    <SearchForm ref={searchInputRef} />
                  </Transition.Fade>
                  <Transition.Fade
                    className={classNames(
                      'flex items-center justify-center w-full h-full',
                      isOpen && 'pointer-events-none',
                    )}
                    initial={false}
                    data-testid="menu-transition"
                    show={!isOpen}
                  >
                    <MainMenu
                      onOpen={() => setIsMainMenuOpen(true)}
                      onClose={() => setIsMainMenuOpen(false)}
                    >
                      {children}
                    </MainMenu>
                  </Transition.Fade>
                </Grid>
                <Flex className="items-stretch flex-1">
                  <Transition.Fade
                    as={Flex}
                    className="relative items-center justify-end flex-1 desktop-navigation__actions"
                    show={!isOpen}
                    initial={false}
                  >
                    <Popover.Trigger>
                      <IconButton
                        icon={<Icon.Search width={20} height={20} />}
                        size="lg"
                        aria-label={t('action.open')}
                      />
                    </Popover.Trigger>
                    <WishlistButton />
                    <AccountMenu />
                    <div className="flex items-center justify-center w-12 h-12">
                      {basketComponent}
                    </div>
                  </Transition.Fade>
                  <Portal>
                    <Popover.Backdrop className="z-30" />
                    <Transition.Fade className="fixed left-0 right-0 w-screen top-[var(--header-height)] max-h-[calc(100vh-var(--header-height))] overflow-y-auto z-40">
                      <Popover.Content>
                        {typeof searchComponent === 'function'
                          ? searchComponent(searchContext)
                          : searchComponent}
                      </Popover.Content>
                    </Transition.Fade>
                  </Portal>
                </Flex>
              </Flex>
            </Wrapper>
          </motion.header>
        </div>
      )}
    </Popover>
  )
}
