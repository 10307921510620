import { Text } from '@lounge-fe/ui-kit'

import { SearchMenuItemProps } from './types'

export const MenuItem = ({ children, ...props }: SearchMenuItemProps) => (
  <Text
    as="button"
    variant="body-sm"
    className="mb-2 capitalize cursor-pointer inline"
    {...props}
  >
    {children}
  </Text>
)
