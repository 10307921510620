import type { ElementType } from 'react'

import { Text } from '../Text'

import { HeadingProps, HeadingVariant } from './types'

const variantTagMap: {
  [key in HeadingVariant]: ElementType
} = {
  'title-one': 'h1',
  'title-two': 'h2',
  'title-three': 'h3',
  'title-four': 'h4',
  'title-five': 'h5',
  'title-six': 'h6',
}

const defaultElement = 'h1'

export const Heading = <C extends ElementType = typeof defaultElement>({
  variant = 'title-one',
  as,
  ...props
}: HeadingProps<C>): JSX.Element => {
  return <Text variant={variant} as={variantTagMap[variant]} {...props} />
}
