import {
  useState,
  ReactNode,
  createContext,
  useMemo,
  useContext,
  useCallback,
  forwardRef,
  FC,
} from 'react'
import classNames from 'classnames'

import { Icon } from '../Icon'

import {
  AccordionContextState,
  AccordionProps,
  AccordionVariants,
  IconPosition,
} from './types'

const defaultIconPositionMap: { [key in AccordionVariants]: IconPosition } = {
  basic: 'end',
  icon: 'start',
}

const defaultOpenIconMap: { [key in AccordionVariants]: ReactNode } = {
  basic: <Icon.ChevronUp color="#7D7D7D" className="rotate-0 w-3 h-3" />,
  icon: <Icon.Minus />,
}

const defaultClosedIconMap: { [key in AccordionVariants]: ReactNode } = {
  basic: <Icon.ChevronUp color="#7D7D7D" className="-rotate-180 w-3 h-3" />,
  icon: <Icon.Plus variant="light" />,
}

const AccordionContext = createContext<AccordionContextState>(
  {} as AccordionContextState
)

export const Accordion = forwardRef<HTMLDivElement, AccordionProps>(
  (
    {
      children,
      exclusive = false,
      variant = 'basic',
      iconPosition = defaultIconPositionMap[variant],
      openIcon = defaultOpenIconMap[variant],
      closedIcon = defaultClosedIconMap[variant],
      className,
      disableToggle = false,
      divider = true,
      id,
    }: AccordionProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const [openItems, setOpenItems] = useState<string[]>([])

    const handleClick = useCallback(
      (id: string, open: boolean) => {
        if (!disableToggle) {
          return exclusive
            ? setOpenItems(open ? [id] : [])
            : setOpenItems((prevItems) =>
                open
                  ? [...prevItems, id]
                  : prevItems.filter((item) => item !== id)
              )
        }
        setOpenItems([id])
      },
      [exclusive, disableToggle]
    )

    const context = useMemo<AccordionContextState>(
      () => ({
        openItems,
        handleClick,
        iconPosition,
        openIcon,
        closedIcon,
        disableToggle,
        divider
      }),
      [
        openItems,
        handleClick,
        iconPosition,
        openIcon,
        closedIcon,
        disableToggle,
        divider
      ]
    )

    return (
      <AccordionContext.Provider value={context}>
        <div
          id={id}
          ref={ref}
          className={classNames('flex flex-col', className)}
        >
          {typeof children === 'function' ? children(context) : children}
        </div>
      </AccordionContext.Provider>
    )
  }
)

export const useAccordionContext = () => useContext(AccordionContext)
