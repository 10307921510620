import classNames from 'classnames'

import { ToggleGroup, ToggleGroupItemProps, sizeVariant } from '../ToggleGroup'

const variantSizes: { [key in sizeVariant]: string } = {
  sm: 'choice-chips__item--size-sm',
  md: 'choice-chips__item--size-md',
}

export const Item = ({
  className,
  disabled,
  enableDisabledStyles = true,
  size = 'md',
  ...props
}: ToggleGroupItemProps): JSX.Element => (
  <ToggleGroup.Item
    disabled={disabled}
    className={classNames(
      'choice-chips__item',
      variantSizes[size],
      enableDisabledStyles && disabled && 'choice-chips__item--disabled',
      className
    )}
    {...props}
  />
)
