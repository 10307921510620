import classNames from 'classnames'
import { Text, Button } from '@lounge-fe/ui-kit'
import { ContentProps } from './types'

export const Content = ({
  className,
  headingClassname,
  bodyClassname,
  buttons,
  caption,
  heading,
  body,
}: ContentProps) => {
  return (
    <div
      className={classNames(
        'flex flex-col justify-center basis-full md:basis-2/5',
        'mt-2 px-6 py-8 md:py-20 md:mt-0 lg:pl-14 lg:pr-10',
        className,
      )}
    >
      {caption && <Text className="mb--1">{caption}</Text>}
      {heading && (
        <Text
          variant="title-four"
          className={classNames('mb-4', headingClassname)}
        >
          {heading}
        </Text>
      )}
      {body && (
        <Text
          variant="body-lg"
          className={classNames('pb-6 text-[#757272]', bodyClassname)}
        >
          {body}
        </Text>
      )}

      <div className="flex flex-row gap-3 flex-wrap">
        {buttons.map((button, idx) =>
          button.label && button.link ? (
            <Button
              key={idx}
              as="a"
              href={button.link}
              className="md:w-fit w-full"
              variant={idx % 2 === 0 ? 'solid' : 'outline'}
            >
              {button.label}
            </Button>
          ) : null,
        )}
      </div>
    </div>
  )
}
