import { useTranslation } from 'react-i18next'
import { PropsWithChildren } from 'react'
import classNames from 'classnames'

import { Text } from '../../components/Text'

export const Notice = ({
  children,
  variant = 'light',
}: PropsWithChildren<{ variant?: 'light' | 'dark' }>) => {
  const { t } = useTranslation()

  const variantMap = {
    light: 'product-card__notice--light',
    dark: 'product-card__notice--dark',
  }

  return (
    <div
      className={classNames(
        'product-card__notice',
        variant && variantMap[variant],
      )}
    >
      <Text className="font-regular capitalize leading-md" variant="body-sm">
        {children}
      </Text>
    </div>
  )
}
