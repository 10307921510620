import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useId,
  useMemo,
  useState,
} from 'react'

import {
  DisclosureProvider,
  useDisclosureContext,
} from '../../hooks/useDisclosure'
import { UseDisclosureReturn } from '../../hooks/useDisclosure/types'

import { PopoverContextState, PopoverOpenReason, PopoverProps } from './types'
import { usePopoverStore } from './store'
import { usePopoverGroupContext } from './Group'

const PopoverContext = createContext<PopoverContextState>(
  {} as PopoverContextState,
)

export const usePopoverContext = (): PopoverContextState &
  UseDisclosureReturn => {
  const popoverContext = useContext(PopoverContext)
  const disclosureContext = useDisclosureContext()
  return {
    ...disclosureContext,
    ...popoverContext,
  }
}

const PopoverComponent: FC<PopoverProps> = ({
  children,
  scrollLock = true,
  focusLock = true,
  exclusive = false,
  shards = [],
}) => {
  const popoverId = useId()
  const contentId = useId()
  const triggerId = useId()

  const {
    id: groupId,
    isInGroup,
    popoverIds,
    setPopoverIds,
  } = usePopoverGroupContext()

  const id = isInGroup ? groupId : popoverId

  const { activeId, setActiveId } = usePopoverStore(
    ({ activeId, setActiveId }) => ({
      activeId,
      setActiveId,
    }),
  )

  const [openReason, setOpenReason] = useState<PopoverOpenReason | null>(null)

  const disclosureContext = useDisclosureContext()

  const onOpen = useCallback(() => {
    if (exclusive) {
      disclosureContext.onOpen()
      return
    }

    setActiveId(id)
    if (isInGroup) {
      setPopoverIds((prev) => [...prev, popoverId])
    }
  }, [id, isInGroup, exclusive])

  const onClose = useCallback(() => {
    if (exclusive) {
      disclosureContext.onClose()
      return
    }

    if (isInGroup) {
      setPopoverIds((prev) => prev.filter((_id) => _id !== popoverId))
    } else if (activeId === popoverId) {
      setActiveId(null)
    }
  }, [activeId, popoverId, isInGroup, exclusive])

  const popoverContext: PopoverContextState = useMemo(
    () => ({
      popoverId,
      openReason,
      setOpenReason,
      contentId,
      triggerId,
      onOpen,
      onClose,
      scrollLock,
      focusLock,
      shards,
    }),
    [
      openReason,
      triggerId,
      contentId,
      setOpenReason,
      popoverId,
      onOpen,
      onClose,
    ],
  )

  const mergedContext = useMemo(
    () => ({ ...disclosureContext, ...popoverContext }),
    [disclosureContext, popoverContext],
  )

  useEffect(() => {
    const isActive = activeId === id
    const isActiveWithinGroup = isInGroup
      ? popoverIds.includes(popoverId)
      : true

    if (
      !exclusive &&
      (!isActive || !isActiveWithinGroup) &&
      disclosureContext.isOpen
    ) {
      disclosureContext.onClose()
    }

    if (
      !exclusive &&
      isActive &&
      isActiveWithinGroup &&
      !disclosureContext.isOpen
    ) {
      disclosureContext.onOpen()
    }
  }, [
    activeId,
    isInGroup,
    popoverIds,
    id,
    disclosureContext.isOpen,
    disclosureContext.onClose,
    disclosureContext.onOpen,
  ])

  useEffect(() => {
    window.addEventListener('popstate', onClose)
    return () => {
      window.removeEventListener('popstate', onClose)
    }
  }, [onClose])

  return (
    <PopoverContext.Provider value={popoverContext}>
      {typeof children === 'function' ? children(mergedContext) : children}
    </PopoverContext.Provider>
  )
}

export const Popover: FC<PopoverProps> = ({
  isOpen,
  onClose,
  onOpen,
  ...props
}) => (
  <DisclosureProvider isOpen={isOpen} onClose={onClose} onOpen={onOpen}>
    <PopoverComponent {...props} />
  </DisclosureProvider>
)
