import { Children, cloneElement, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'

import {
  Accordion,
  Box,
  Flex,
  Popover,
  Text,
  Transition,
  Portal,
  Link,
  usePopoverContext,
} from '@lounge-fe/ui-kit'
import { NavigationTile } from '../NavigationTile'

import { MenuPopoverProps } from './types'
import { TileCarousel } from './TileCarousel'

export const MenuPopover = ({
  carouselTitle,
  children,
  tiles,
  isTablet,
  headerComponent,
  className,
}: MenuPopoverProps) => {
  const { isOpen } = usePopoverContext()
  const hasOpened = useRef(false)

  useEffect(() => {
    if (isOpen) {
      hasOpened.current = true
    }
  }, [isOpen])

  return (
    <Portal>
      <Transition.Slide
        direction={isTablet ? 'left' : 'top'}
        className={classNames(
          'fixed top-0 left-0 right-0 height-screen sm:max-w-[498px] bg-white bottom-0 z-40',
          className,
        )}
        keepMounted
      >
        <Popover.Content className="h-full max-h-full overflow-y-auto">
          <Flex className="flex-col h-full">
            {headerComponent}
            <Flex className="flex-col justify-between h-full">
              <Box as="nav" className="px-6 py-2">
                <Accordion variant="basic" divider={false} exclusive>
                  {Children.map(children, (item, index) =>
                    item.props.href ? (
                      <Link
                        variant="body-sm"
                        className="py-3 text-md"
                        href={item.props.href}
                        key={index}
                      >
                        {item.props.title}
                      </Link>
                    ) : (
                      <Accordion.Item
                        title={
                          <Text
                            variant="body-sm"
                            className="text-md font-regular"
                          >
                            {item.props.title}
                          </Text>
                        }
                        id={index.toString()}
                        key={index}
                      >
                        <Flex className="flex-col gap-4 mb-4">
                          {Children.map(
                            item.props.children,
                            (subItem, index) => {
                              return cloneElement(
                                subItem,
                                index === 0 &&
                                  item.props.title.trim().toLowerCase() ===
                                    subItem.props.title?.trim().toLowerCase()
                                  ? { title: undefined }
                                  : {},
                              )
                            },
                          )}
                        </Flex>
                      </Accordion.Item>
                    ),
                  )}
                </Accordion>
              </Box>
              <TileCarousel title={carouselTitle}>
                {tiles.map((props, idx) => (
                  <NavigationTile
                    showImage={isOpen || hasOpened.current}
                    key={idx}
                    {...props}
                  />
                ))}
              </TileCarousel>
            </Flex>
          </Flex>
        </Popover.Content>
      </Transition.Slide>
    </Portal>
  )
}
