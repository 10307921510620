import { Badge } from '../../components/Badge'
import { createCompoundComponent } from '../../utilities'

import { ProductCard as BaseProductCard } from './ProductCard'
import { Image } from './Image'
import { Details } from './Details'
import { FavouriteButton } from './FavouriteButton'
import { Skeleton } from './Skeleton'
import { QuickAdd } from './QuickAdd'
import { ImageCarousel } from './ImageCarousel'
import { ImageWrapper } from './ImageWrapper'
import { Swatches } from './Swatches'
import { Notice } from './Notice'

export type { ProductCardProps } from './types'

export const ProductCard = createCompoundComponent(BaseProductCard, {
  Image,
  Details,
  FavouriteButton,
  Badge,
  Skeleton,
  Swatches,
  QuickAdd,
  ImageCarousel,
  ImageWrapper,
  Notice,
})
